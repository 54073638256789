import swal from "sweetalert";

export const showError = (title = "Error Occurred", msg = "") => {
	return swal(title, msg, "error");
};
export const getGreetings = () => {
	const date = new Date();
	let hours = date.getHours();
	return `Good ${(hours < 12) ? "Morning" : ((hours <= 18 && hours >= 12) ? "Afternoon" : "Evening")}`;
}

export const getSubdomain = (hostname) => {
	let hostArr = hostname.split(".");
	if (Array.isArray(hostArr) && hostArr.length > 0) {
		if (hostArr[0] !== "www")
			return hostArr[0];
		else
			return hostArr[1];
	}
}
