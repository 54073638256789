import { useEffect, useState } from 'react';
import moment from 'moment';
import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import classes from './style.module.scss';

const Timer = ({ active = false }) => {
    const [ date, setDate ] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    return (
        <Row 
            className={classNames("align-items-end rounded-xlg p-3 p-md-4 pb-2 w-100", {
                'text-white bg-danger-gradient': active,
                'text-danger': !active
            })} 
            noGutters
        >
            <Col xs={12} className={classNames("text-center leading-tight", classes.time)}>
                <span>{moment(date).format('HH:mm:ss')}</span>
                <span className={classNames('mb-0 ml-1', classes.period)}>
                    {moment(date).format('A')}
                </span>
            </Col>
            <Col xs={12} className="text-white text-center p-1">
                <p className={classNames('mb-0', classes.date)}>
                    {moment(date).format('dddd, MMMM Do, YYYY')}
                </p>
            </Col>
        </Row>
    )
}

export default Timer;