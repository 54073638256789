import {
    getLateCheckOutReasonService
} from '../../Services/LateCheckOutReasonService';

export async function GetLateCheckOutReasons(dispatch) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await getLateCheckOutReasonService();
        if (response.data) {
            dispatch({ type: 'GET_REASONS_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}