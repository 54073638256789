import './styles/global.scss';

import AppRouter from './Router';

function App() {
  return (
    <div className="App">
      {/* <Topbar /> */}
      <AppRouter />
      {/* <Footer /> */}
    </div>
  );
}

export default App;
