import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AppContainer from "../Components/AppContainer";

import Login from "../Views/Login";
import Dispatch from '../Views/Dispatch';
import StoreWrapper from "./StoreWrapper";
  
const AppRouter = () => {
    return (
        <StoreWrapper>
            <Router>
                <AppContainer>
                    <Routes>
                        <Route exact path="/" element={<Login />} />
                        <Route exact path="/dispatch" element={<Dispatch />} />
                    </Routes>
                </AppContainer>
            </Router>
        </StoreWrapper>
    )
}

export default AppRouter;

