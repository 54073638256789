import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import classNames from 'classnames';

// fieldNames = [[ key, label, customRenderFunc ]]
const CustomTable = ({
    fieldNames = [], thClassName='', tdClassName='', loading = false, data = []
}) => {
    const renderField = (key, i, item, customRenderFunc) => {
        let field = <td 
            className={classNames("text-center v-align-middle", tdClassName)} 
            key={i}
        >
            {item[key]}
        </td>; // rendering normal field data
        if(customRenderFunc) field = <td 
            className={classNames("text-center v-align-middle", tdClassName)}
            key={i}
        >
            {customRenderFunc(item)}
        </td>; // rendering custom field like "checkbox"
        return field;
    }

    return (
        <Table borderless>
            <thead>
                <tr style={{ whiteSpace: "nowrap", cursor:"pointer" }}>
                    { // render header items
                        fieldNames.map( ([key, label], i) => { // field = [key, label, customRenderFunc]
                            return (
                                <th key={i} className={classNames("text-center", thClassName)}>
                                    { label }
                                </th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {
                    !loading ?
                        Array.isArray(data)
                            ? data.length > 0 ? data
                                .map((item, j) =>
                                    <tr key={j}>
                                        {
                                            fieldNames.map( ([key, label, customRenderFunc],i) =>
                                                renderField(key, label, item, customRenderFunc)
                                            )
                                        }
                                    </tr>
                                )
                            : <tr><td colSpan={fieldNames.length} className="text-center font-weight-bold text-white">No Data Found</td></tr>
                            : <tr><td colSpan={fieldNames.length} className="text-center font-weight-bold text-white">No Data Found</td></tr>
                        : <tr>
                            <td colSpan={fieldNames.length} className="text-center">
                                <Spinner animation="border" variant="primary" />
                            </td>
                        </tr>
                }
            </tbody>
        </Table>
    )
}

export default CustomTable;