export const initialStateAccountSetting = {
    settings: {},
    loading: false,
    errorMessage: null,
};

export const AccountSettigReducer = (state = initialStateAccountSetting, action) => {
    switch (action.type) {
        case "REQUEST_LOADING":
            return {
                ...state,
                loading: true
            }
        case "REPORT_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }
        case "GET_ACCOUNT_SETTINGS_SUCCESS":
            return {
                ...state,
                loading: false,
                settings: action.payload
            }
        case "STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
