import classNames from "classnames";

import Topbar from "../Topbar";
import Loader from "../Loader";

const ViewLayout = ({ 
    children, 
    className, 
    pathName, 
    loading = false,
    showTopbar = false
}) => {
    return  <div className="height-screen d-flex flex-column">
        { showTopbar && <Topbar pathName={pathName} /> }
        <main className={classNames('flex-grow-1 container-fluid', className)}>
            {children}
        </main>
        <Loader show={loading} />
        {/* <Footer /> */}
    </div>
}

export default ViewLayout;