import { useReducer } from "react";

// Reducers
import { initialStateAuth, AuthReducer } from "../Context/Reducer/AuthReducer";
import { initialStateDriverActual, DriverActualReducer } from '../Context/Reducer/DriverActualReducer';
import { initialStateLateCheckInReason, LateCheckInReducer } from "../Context/Reducer/LateCheckInReasonReducer";
import { initialStateLateCheckOutReason, LateCheckOutReducer } from "../Context/Reducer/LateCheckOutReasonReducer";
import { initialStateAccountSetting, AccountSettigReducer } from "../Context/Reducer/AccountSettingReducer";

// \End of Reducers

// Context
import { 
    AccountSettingContext,
    AuthContext, DriverActualContext, LateCheckInReasonContext, LateCheckOutReasonContext
} from "../Context";
// \End of Context


const StoreWrapper = ({ children }) => {
    // Store
    const [ auth, authDispatch ] = useReducer(AuthReducer, initialStateAuth);
    const [ driverActual, driverActualDispatch ] = useReducer(DriverActualReducer, initialStateDriverActual);
    const [ lateCheckOutReason, lateCheckOutReasonDispatch ] = useReducer(LateCheckOutReducer, initialStateLateCheckOutReason);
    const [ lateCheckInReason, lateCheckInReasonDispatch ] = useReducer(LateCheckInReducer, initialStateLateCheckInReason);
    const [ settings, settingsDispatch ] = useReducer(AccountSettigReducer, initialStateAccountSetting);

    // \End of Store

    return (
        <AuthContext.Provider value={{ auth, authDispatch }}>
            <AccountSettingContext.Provider value={{ settings, settingsDispatch }}>
                <DriverActualContext.Provider value={{ driverActual, driverActualDispatch }}>
                    <LateCheckInReasonContext.Provider value={{ lateCheckInReason, lateCheckInReasonDispatch}}>
                        <LateCheckOutReasonContext.Provider value={{ lateCheckOutReason, lateCheckOutReasonDispatch}}>
                            {children}
                        </LateCheckOutReasonContext.Provider>
                    </LateCheckInReasonContext.Provider>
                </DriverActualContext.Provider>
            </AccountSettingContext.Provider>
        </AuthContext.Provider>
    )
}

export default StoreWrapper;