import {
    getDriverInfo,
    loginDriverService
} from '../../Services/AuthService';

export async function GetDriverInfo(dispatch, driverReferenceID) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await getDriverInfo(driverReferenceID);
        if (response.data) {
            dispatch({ type: 'GET_DRIVER_INFO_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}

export async function loginDriver(dispatch, driverReferenceID, timeClockPin) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await loginDriverService(driverReferenceID, timeClockPin);
        if (response.data) {
            localStorage.setItem('user', JSON.stringify(response.data.user));
            localStorage.setItem('token', JSON.stringify(response.data.token));
            dispatch({ type: 'GET_DRIVER_INFO_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}