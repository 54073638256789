import service from ".";

export const getDriverActualService = () => {
    return service.get('/driver-actuals');
}

export const addDriverActualService = (data) => {
    return service.post('/driver-actuals', data);
}

export const editDriverActualService = (id, data) => {
    return service.put(`/driver-actuals/${id}`, data);
}