import { useState } from "react";
import classNames from 'classnames';
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import classes from './style.module.scss';
import Timer from "../../Components/Timer";
import { getGreetings ,getSubdomain} from "../../utils/helper";
import ViewLayout from "../../Components/ViewLayout";
import NumberInput from "../../Components/NumberInput";
import companyConfig from '../../company-config.json';
import { useAuthState, GetDriverInfo, loginDriver } from '../../Context'

const Login = () => {
    const navigate = useNavigate();
    const [value, setValue] = useState('');
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        driverValdiated: false,
        driverInfo: null,
        driverReferenceID: null,
        password: null
    });

    const { authDispatch } = useAuthState();

    let subdomain = getSubdomain(window.location.hostname);
    let configInfo = companyConfig[subdomain];

    const onSubmitClick = async () => {
        try {
            setLoading(true);
            if (!formData.driverValdiated) {
                let info = await GetDriverInfo(authDispatch, value);
                if (info) {
                    setFormData({
                        driverValdiated: true,
                        driverInfo: info,
                        driverReferenceID: value
                    });
                }
                setValue('');
                setLoading(false);
            } else {
                console.log(value,formData)
                let driver = await loginDriver(authDispatch, formData.driverReferenceID, value);
                if (driver) {
                    navigate('/dispatch');
                } else {
                    setValue('');
                    setLoading(false);
                }
            }
        } catch (error) { }
    }

    return <ViewLayout
        loading={loading}
        className="bg-gray-light d-flex align-items-center justify-content-center"
    >
        <div className={classNames('rounded shadow-lg', classes.container)}>
            <Row className="bg-gray-dark h-100 rounded-lg" noGutters>
                <Col xs={12} lg={8} className="d-flex flex-column">
                    <Row noGutters>
                        <Col xs={5}>
                            <img
                                src={configInfo.logo}
                                alt="Logo"
                                className="w-100 m-2"
                            />
                        </Col>
                    </Row>
                    <div className="flex-grow-1 d-flex flex-column justify-content-around">
                        <div className="p-2">
                            <Timer
                                active={formData.driverValdiated}
                            />
                            {formData.driverValdiated && <Row className="mt-3" noGutters>
                                <Col xs={12}>
                                    <h2 className="text-white">
                                        {getGreetings()}
                                        <br />
                                        <b className="text-capitalize">
                                            {`${formData.driverInfo.firstName} ${formData.driverInfo.lastName}`}
                                        </b>
                                    </h2>
                                </Col>
                            </Row>}
                        </div>
                    </div>
                </Col>
                <Col xs={12} lg={4} className="bg-gray-darker p-3 d-flex align-items-center rounded-right">
                    <NumberInput
                        val={value}
                        onSubmitClick={onSubmitClick}
                        onValChange={setValue}
                        idValidated={formData.driverValdiated}
                    />
                </Col>
            </Row>
        </div>
    </ViewLayout>
}

export default Login;