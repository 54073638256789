import { useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { 
    useLateCheckInReasonState, useLateCheckOutReasonState, 
    GetLateCheckInReasons, GetLateCheckOutReasons 
} from "../../Context";

const LateCheckInOutModal = ({ 
    type="In", show=false, onSubmitClick = () => {}, onClose=() => {}
}) => {
    const [ reasonId, setReasonId ] = useState();
    const [ optionsArr, setOptionsArr ] = useState([]);
    const { lateCheckInReasonDispatch } = useLateCheckInReasonState();
    const { lateCheckOutReasonDispatch } = useLateCheckOutReasonState();

    useEffect(() => {
        (async () => {
            let options = [];
            if(type === "In") {
                options = await GetLateCheckInReasons(lateCheckInReasonDispatch);
            } else if(type === "Out") {
                options = await GetLateCheckOutReasons(lateCheckOutReasonDispatch)
            }
            setOptionsArr(options);
        })()
    }, [lateCheckInReasonDispatch, lateCheckOutReasonDispatch, type]);

    const onSubmit = () => {
        onSubmitClick(reasonId);
    }

    return (
        <Modal show ={show} className="rounded-md" animation={false} centered onHide={onClose}>
            <Modal.Header className="bg-danger-gradient text-white px-3 py-2">
                <Modal.Title className="font-weight-bold">
                    <i className="fa fa-warning mr-2" />
                    Late Check-{type}
                </Modal.Title>
            </Modal.Header>

            <Modal.Body className="bg-dark">
                <Form.Group controlId="reason">
                    <Form.Label className="text-white">
                        Select Reason for Late Check-{type}
                    </Form.Label>
                    <Form.Control 
                        as="select"
                        value={reasonId}
                        onChange={(e) => setReasonId(e.target.value)}
                    >
                        { optionsArr.map( item => <option key={item.id} value={item.id}>{item.description}</option>) }
                    </Form.Control>
                </Form.Group>
                <div className="text-center">
                    <Button 
                        variant="danger" 
                        onClick={onSubmit}
                        className="bg-danger-gradient border-0 rounded-md"
                    >
                        SUBMIT
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={onClose}
                        className="rounded-md ml-1"
                    >
                        CANCEL
                    </Button>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default LateCheckInOutModal;