export const initialStateDriverActual = {
    dispatches: null,
    actual: null,
    loading: false,
    errorMessage: null,
};

export const DriverActualReducer = (state = initialStateDriverActual, action) => {
    switch (action.type) {
        case "REQUEST_LOADING":
            return {
                ...state,
                loading: true
            }
        case "REPORT_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }
        case "GET_DRIVER_ACTUAL_SUCCESS":
            return {
                ...state,
                loading: false,
                dispatches: action.payload
            }
        case "ADD_DRIVER_ACTUAL_SUCCESS":
        case "EDIT_DRIVER_ACTUAL_SUCCESS":
            return {
                ...state,
                loading: false,
                actual: action.payload
            }
        case "STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
