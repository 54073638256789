import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loader from "../Loader";

// component to get initial data
const AppContainer = ({ children }) => {
    const navigate = useNavigate();
    const [ firstLoaded, setFirstLoaded ] = useState(false);
    const user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')): ''

    useEffect(() => {
        if(!user) {
            navigate('/');
        } else {}
        setFirstLoaded(true);
    }, [user, navigate]);

    return firstLoaded ? children : <Loader />;
}

export default AppContainer;