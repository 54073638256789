import { useContext, createContext } from "react";

// Contexts
export const AuthContext = createContext();
export const DriverActualContext = createContext();
export const LateCheckInReasonContext = createContext();
export const LateCheckOutReasonContext = createContext();
export const AccountSettingContext = createContext();

// Users
export const useAuthState = () => {
    const authContext = useContext(AuthContext);
    if(!authContext) {
        throw new Error("useAuthState must be used within AuthContext.Provider");
    }
    return authContext;
}
export const useDriverActualState = () => {
    const driverActualContext = useContext(DriverActualContext);
    if(!driverActualContext) {
        throw new Error('useDriverActualState must be used within DriverContext.Provider');
    }
    return driverActualContext;
}
export const useLateCheckInReasonState = () => {
    const lateCheckInContext = useContext(LateCheckInReasonContext);
    if(!lateCheckInContext) {
        throw new Error('useLateCheckInReasonState must be used within LateCheckInReasonContext.Provider');
    }
    return lateCheckInContext;
}
export const useLateCheckOutReasonState = () => {
    const lateCheckOutContext = useContext(LateCheckOutReasonContext);
    if(!lateCheckOutContext) {
        throw new Error('useLateCheckOutReasonState must be used within LateCheckOutReasonContext.Provider');
    }
    return lateCheckOutContext;
}
export const useAccountSettingState = () => {
    const accountSettingContext = useContext(AccountSettingContext);
    if(!accountSettingContext) {
        throw new Error('useAccountSettingState must be used within AccountSettingContext.Provider')
    }
    return accountSettingContext;
}