import { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import { getSubdomain } from '../../utils/helper';
import companyConfig from '../../company-config.json';

const Topbar = () => {
    const navigate = useNavigate();
    const [date, setDate] = useState(new Date());
    const [logo, setLogo] = useState();

    useEffect(() => {
        let subdomain = getSubdomain(window.location.hostname);
        let configInfo = companyConfig[subdomain];
        setLogo(configInfo.logo);
        const timer = setInterval(() => {
            setDate(new Date());
        }, 1000);
        return () => clearInterval(timer);
    }, []);

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        navigate('/');
    }

    return (
        <header>
            <Row className='bg-gray-darker' noGutters>
                <Col xs={12} className="p-2">
                    <Row className='text-white' noGutters>
                        <Col xs={2}>
                            <img
                                src={logo}
                                alt="Logo"
                                className="w-100 m-2"
                            />
                        </Col>
                        <div className='flex-grow-1 d-flex align-items-center justify-content-end p-2'>
                            <h5 className='mb-0 font-weight-bold'>
                                {moment(date).format('dddd, MMMM Do, YYYY')} <span className='text-danger'>{moment(date).format('HH:mm:ss A')}</span>
                                {/* Monday, March 7th, 2022 <span className='text-danger'>9:33:24 AM</span> */}
                            </h5>
                        </div>
                        <div className='flex-shrink-1 d-flex align-items-center'>
                            <Button
                                variant='danger'
                                onClick={handleLogout}
                                className='rounded-md px-3 bg-danger-gradient border-0'
                            >
                                <p className='mb-0 font-weight-bold'><i className='fa fa-sign-out' /> LOG OUT</p>
                            </Button>
                        </div>
                    </Row>
                </Col>
            </Row>
        </header>
    )
}

export default Topbar;