import classNames from 'classnames';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import FormControl from 'react-bootstrap/FormControl';

const CalcButton = ({ 
    children, className, style={}, onClick=() => {}, xs=4, disabled=false, type="button", variant="secondary"
}) => {
    return (
        <Col xs={xs} className="text-center p-1">
            <button
                type={type}
                disabled={disabled}
                className={classNames(
                    "btn btn-secondary text-white w-100 btn-xlg rounded-lg", 
                    {
                        'btn-danger': variant === 'danger',
                        'btn-secondary': variant === 'secondary'
                    },
                    className
                )}
                style={style}
                onClick={onClick}
            >
                {children}
            </button>
        </Col>
    )
}

const NumberInput = ({ 
    val, onSubmitClick = () => {}, onValChange = () => {}, idValidated
}) => {
    const onButtonClick = (key) => {
        if([1,2,3,4,5,6,7,8,9,0].includes(key)) {
            onValChange((val || '')+ '' + key);
        } else if(key === '<') {
            onValChange(val.substr(0, val.length - 1));
        } else if(key === '-') {
            onValChange('');
        }
    }
    const onFormSubmit = (e) => {
        e.preventDefault();
        if(onSubmitClick) onSubmitClick(val);
    }
    return (
        <form onSubmit={onFormSubmit}>
            <Row noGutters>
                <Col xs={12}>
                    <h4 className='font-weight-bold text-white'>
                        { idValidated ? 'Enter Your PIN' : 'Enter your Employee ID Number' }
                    </h4>
                </Col>
                <Col xs={12} className="px-1">
                    <FormControl
                        type="number"
                        className="w-100 form-control-xlg rounded-md"
                        value={val}
                        required
                        onChange={(e) => onValChange(e.target.value)}
                    />
                </Col>
                <Col xs={12}>
                    <Row noGutters className='py-1 rounded-md'>
                        <CalcButton
                            onClick={() => onButtonClick(1)}
                        >
                            <span className='h2'>1</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(2)}
                        >
                            <span className='h2'>2</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(3)}
                        >
                            <span className='h2'>3</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(4)}
                        >
                            <span className='h2'>4</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(5)}
                        >
                            <span className='h2'>5</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(6)}
                        >
                            <span className='h2'>6</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(7)}
                        >
                            <span className='h2'>7</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(8)}
                        >
                            <span className='h2'>8</span>
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(9)}
                        >
                            <span className='h2'>9</span>
                        </CalcButton>
                        <CalcButton
                            disabled={!val}
                            onClick={() => onButtonClick('-')}
                        >
                            <i className="fa fa-ban" />
                        </CalcButton>
                        <CalcButton
                            onClick={() => onButtonClick(0)}
                        >
                            <span className='h2'>0</span>
                        </CalcButton>
                        <CalcButton
                            disabled={!val}
                            onClick={() => onButtonClick('<')}
                        >
                            <i className="fa fa-arrow-left" />
                        </CalcButton>
                        <CalcButton xs={12} type="submit" variant='danger'>
                            <span className='h2 font-weight-bold'>SUBMIT</span>
                        </CalcButton>
                    </Row>
                </Col>
            </Row>
        </form>
    )
}

export default NumberInput;