export const initialStateAuth = {
    info: null,
    loading: false,
    errorMessage: null,
};

export const AuthReducer = (state = initialStateAuth, action) => {
    switch (action.type) {
        case "REQUEST_LOADING":
            return {
                ...state,
                loading: true
            }
        case "REPORT_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }
        case "GET_DRIVER_INFO_SUCCESS":
            return {
                ...state,
                loading: false,
                info: action.payload
            }
        case "STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
