import {
    getDriverActualService, addDriverActualService, editDriverActualService
} from '../../Services/DriverActualService';

export async function GetDriverActuals(dispatch) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await getDriverActualService();
        if (response.data) {
            dispatch({ type: 'GET_DRIVER_ACTUAL_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}

export async function addDriverActual(dispatch, data) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await addDriverActualService(data);
        if (response.data) {
            dispatch({ type: 'ADD_DRIVER_ACTUAL_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}

export async function editDriverActual(dispatch, id, data) {
    dispatch({ type: 'REQUEST_LOADING' });
    try {
        let response = await editDriverActualService(id, data);
        if (response.data) {
            dispatch({ type: 'EDIT_DRIVER_ACTUAL_SUCCESS', payload: response.data });
            return response.data;
        }

        dispatch({ type: 'REPORT_ERROR', error: response.data.errors[0] });
        return;
    } catch (error) {
        dispatch({ type: 'REPORT_ERROR', error: error.message });
    }
}