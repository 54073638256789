import axios from 'axios';
import { showError, getSubdomain } from '../utils/helper';
import appConfig from '../config.json';
import companyConfig from '../company-config.json';

// export const local = 'https://the-bus-network.uc.r.appspot.com/api';
// export const local = 'https://the-bus-network-ops.uc.r.appspot.com/api';
// export const local = 'https://the-bus-network-qa-338114.uc.r.appspot.com/api';
// export const local = 'https://tbn-bus-network-beta.uc.r.appspot.com/api';
export const baseURL = appConfig.apiUrl;
const baseService = axios.create({ baseURL });
export const Service = axios.create({ baseURL });

let subdomain = getSubdomain(window.location.hostname);
let configInfo = companyConfig[subdomain];

baseService.interceptors.request.use(config => {
    const token = localStorage.getItem('token');
    if (token) {
        config.headers = {
            'Authorization': `Bearer ${JSON.parse(token)}`,
            ...config.headers
        };
    }
    config.params = {
        ...config.params,
        companyID: configInfo.companyID
    };
    return config;
}, error => {
    return Promise.reject(error);
});

baseService.interceptors.response.use(config => {
    return config;
}, error => {
    if (error.response && error.response.data && error.response.data.message) {
        showError(error.response.data.message);
        // localStorage.removeItem('token');

        // dispatch({type: LOGOUT});
        // return Promise.reject({response: {data: {error: 'please login first'}}});
    }
    return Promise.reject(error);
});

export default baseService;


