import moment from 'moment';
import { useCallback, useEffect, useState } from "react";
import { Button } from "react-bootstrap";

import ViewLayout from "../../Components/ViewLayout";
import CustomTable from "../../Components/CustomTable";
// import CustomGrouppedTable from "../../Components/CustomGrouppedTable";
import LateCheckInOutModal from '../../Components/LateCheckInOutModal';
import { GetDriverActuals, useDriverActualState, addDriverActual, editDriverActual, useAccountSettingState, getAccountSettings } from "../../Context";

const saveFormat = 'YYYY-MM-DDTHH:mm:00';
const Dispatch = () => {
    const [ loading, setLoading ] = useState(false);
    const [ modalData, setModalData ] = useState({
        show: false,
        type: null,
        itemId: null,
        time: null,
        lateTypeId: null
    });
    const [ formattedDispatches, setFormattedDispatches ] = useState({
        today: [],
        upcoming: []
    });

    const { settings: { settings }, settingsDispatch } = useAccountSettingState();
    const { driverActualDispatch } = useDriverActualState();

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                let settingsData = await getAccountSettings(settingsDispatch);
                let actuals = await GetDriverActuals(driverActualDispatch);
                let todaysDate = moment().format('MM-DD-yyyy'), differance;
                let todaysActuals = [];
                if(actuals && actuals[todaysDate]) {
                    todaysActuals = actuals[todaysDate].map( item => {
                        if(item.driverStartTime && settingsData.timeclockLockCheckinMinutes) {
                            differance = moment.duration(moment(item.driverStartTime).diff(moment()))
                            if(differance.asMinutes() < settingsData.timeclockLockCheckinMinutes) {
                                return {
                                    ...item,
                                    isEnabled: true
                                }
                            }
                        }
                        return {
                            ...item,
                            isEnabled: settingsData.timeclockLockCheckinMinutes ? false : true
                        }
                    })
                }
                if(Array.isArray(todaysActuals) && todaysActuals.length > 0) {
                    delete actuals[todaysDate];
                }
                setFormattedDispatches({
                    today: todaysActuals,
                    upcoming: actuals
                });
                setLoading(false);
            } catch (error) {
                console.trace(error);
                window.swal(error.message);
            }
        })()
    }, [driverActualDispatch, settingsDispatch]);

    const checkTimeValidation = useCallback(() => {
        let temp, differance;
        let updatedTodaysDispatches = formattedDispatches.today.map(item => {
            temp = {
                ...item
            }
            if(item.driverStartTime && settings.timeclockLockCheckinMinutes) {
                differance = moment.duration(moment(item.driverStartTime).diff(moment()))
                if(differance.asMinutes() < settings.timeclockLockCheckinMinutes) {
                    temp.isEnabled = true;
                }
            }
            return temp;
        });
        setFormattedDispatches({
            ...formattedDispatches,
            today: updatedTodaysDispatches
        });
        // setUpdatedDispatches(updatedDispatch)
    }, [formattedDispatches, settings.timeclockLockCheckinMinutes]);
    
    // running timer every minutes
    useEffect(() => {
        const timer = setInterval(() => {
            checkTimeValidation();
        }, 60000);
        return () => clearInterval(timer);
    }, [checkTimeValidation]);

    const refreshActuals = async () => {
        try {
            setLoading(true);
            let actuals = await GetDriverActuals(driverActualDispatch);
            let todaysDate = moment().format('MM-DD-yyyy');
            let todaysActuals = actuals[todaysDate];
            if(Array.isArray(todaysActuals) && todaysActuals.length > 0) {
                delete actuals[todaysDate];
            }
            setFormattedDispatches({
                today: todaysActuals,
                upcoming: actuals
            });
            setLoading(false);
        } catch (error) {
            
        }
    }
    const checkButtonClick = async (item) => {
        let nowTime = moment().set({ seconds: 0 });
        if(item.driverActualStartTime) {
            // Check Out
            if(nowTime > moment(item.driverEndTime, 'MM-DD-yyyy HH:mm')) {
                setModalData({
                    show: true,
                    type: 'Out',
                    itemId: item.driverActualId,
                    time: nowTime.format(saveFormat)
                });
            } else
                addDriverActualEntry(item.driverActualId, nowTime.format(saveFormat), 'Out');
        } else {
            // Check In
            if(nowTime > moment(item.driverStartTime, 'MM-DD-yyyy HH:mm')) {
                setModalData({
                    show: true,
                    type: 'In',
                    itemId: item.dispatchId,
                    time: nowTime.format(saveFormat)
                });
            } else
                addDriverActualEntry(item.dispatchId, nowTime.format(saveFormat), 'In');
        }
    }
    const onLateReasonModalSubmit = (typeId) => {
        addDriverActualEntry(modalData.itemId, modalData.time, modalData.type, typeId);
    }
    const addDriverActualEntry = async (itemId, time, type, lateTypeId) => {
        // console.log(id, time, type, lateTypeId);
        try {
            let data = {};
            if(type === "In") {
                setLoading(true);
                data = {
                    startTime: time,
                    dispatchID: itemId,
                }
                if(lateTypeId) data.lateCheckInID = lateTypeId;
                await addDriverActual(driverActualDispatch, data);
                await refreshActuals();
                setModalData({
                    show: false,
                    itemId: null,
                    lateTypeId: null,
                    time: null,
                    type: null
                });
                setLoading(false);
            } else if(type === "Out") {
                // edit late checkIn for outEntry
                setLoading(true);
                data = {
                    endTime: time,
                }
                if(lateTypeId) data.lateCheckOutID = lateTypeId;
                await editDriverActual(driverActualDispatch, itemId, data);
                await refreshActuals();
                setModalData({
                    show: false,
                    itemId: null,
                    lateTypeId: null,
                    time: null,
                    type: null
                });
                setLoading(false);
            }
        } catch (error) {
            
        }
    }
    const checkoutButtonRenderer = (item, today = false) => {
        if(!today) return '';
        if(item.driverActualEndTime) {
            return (
                <Button 
                    variant='outline-secondary' 
                    disabled
                    className='rounded-md px-3 font-weight-bold border-danger'
                >
                   <i className='fa fa-check text-danger' />
                </Button>    
            )
        } else {
            return (
                <Button 
                    variant='danger' 
                    disabled={!item.isEnabled}
                    className='rounded-md px-3 bg-danger-gradient border-0 font-weight-bold'
                    onClick={() => checkButtonClick(item)}
                >
                    { item.driverActualStartTime ? 'Check Out' : 'Check In' }
                </Button>
            )
        }
    }
    // const groupTitleRenderer = (title) => {
    //     return moment(title).format('dddd, MMMM Do').toLocaleUpperCase();
    // }
    const actualCheckInRenderer = (item) => {
        if(item.driverActualStartTime) {
            return <span>{item.driverActualStartTime} {item.lateCheckInID ? <i className='fa fa-warning font-weight-bold text-danger' /> : ''}</span>
        } else
            return '';
    }
    const actualCheckOutRenderer = (item) => {
        if(item.driverActualEndTime) {
            return <span>{item.driverActualEndTime} {item.lateCheckOutID ? <i className='fa fa-warning font-weight-bold text-danger' /> : ''}</span>
        } else
            return '';
    }
    const closeLateModal = () => {
        setModalData({
            ...modalData,
            show: false
        });
    }
    return (
        <>
            <ViewLayout 
                loading={loading}
                showTopbar
                className="bg-gray-dark"
            >
                <div className="p-1">
                    <h3 className="text-danger font-weight-bold">TODAY</h3>
                    <div className="bg-gray-darker p-1 rounded-md text-white overflow-auto">
                        <CustomTable
                            data={formattedDispatches.today}
                            thClassName="text-gray-light"
                            tdClassName="text-white mb-0 font-weight-bold h5"
                            fieldNames={[
                                ["routeDescription", "ASSIGNMENT DESCRIPTION"],
                                ["driverStartTime", "SCHEDULED CHECK-IN"],
                                ["driverActualStartTime", "ACTUAL CHECK-IN", actualCheckInRenderer],
                                ["driverEndTime", "SCHEDULED CHECK-OUT"],
                                ["driverActualEndTime", "ACTUAL CHECK-OUT", actualCheckOutRenderer],
                                ["checkoutButton", "", (item) => checkoutButtonRenderer(item, true)]
                            ]}
                        />
                    </div>
                </div>

                {/* <div className="p-1 mt-4">
                    <h3 className="text-danger font-weight-bold">UPCOMING</h3>
                    <div className="bg-gray-darker p-1 rounded-md text-white overflow-auto">
                        <CustomGrouppedTable
                            data={formattedDispatches.upcoming}
                            groupFieldClassName="text-danger font-weight-bold h6"
                            thClassName="text-gray-light"
                            groupLabelRender={groupTitleRenderer}
                            tdClassName="text-white mb-0 font-weight-bold h5"
                            fieldNames={[
                                ["routeDescription", "ASSIGNMENT DESCRIPTION"],
                                ["driverStartTime", "SCHEDULED CHECK-IN"],
                                ["driverActualStartTime", "ACTUAL CHECK-IN"],
                                ["driverEndTime", "SCHEDULED CHECK-OUT"],
                                ["driverActualEndTime", "ACTUAL CHECK-OUT"]
                            ]}
                        />
                    </div>
                </div> */}
            </ViewLayout>
                            
            <LateCheckInOutModal 
                type={modalData.type}
                onClose={closeLateModal}
                show={modalData.show}
                onSubmitClick={(typeId) => onLateReasonModalSubmit(typeId)}
            />
        </>
    )
}

export default Dispatch;