export const initialStateLateCheckOutReason = {
    checkOutReasons: null,
    loading: false,
    errorMessage: null,
};

export const LateCheckOutReducer = (state = initialStateLateCheckOutReason, action) => {
    switch (action.type) {
        case "REQUEST_LOADING":
            return {
                ...state,
                loading: true
            }
        case "REPORT_ERROR":
            return {
                ...state,
                loading: false,
                errorMessage: action.error
            }
        case "GET_REASONS_SUCCESS":
            return {
                ...state,
                loading: false,
                checkOutReasons: action.payload
            }
        case "STOP_LOADING":
            return {
                ...state,
                loading: false
            }
        default:
            throw new Error(`Unhandled action type: ${action.type}`);
    }
};
